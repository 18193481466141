import { __decorate } from "tslib";
import Component from 'vue-class-component';
import Vue from 'vue';
import Axios from 'axios';
import { getEmitter } from '@/emitter';
/**
 * A component for adding a new alias
 */
let NewAlias = class NewAlias extends Vue {
    constructor() {
        super(...arguments);
        /**
         * The new alias
         */
        this.alias = '';
        /**
         * Wether the new alias alert are visible
         */
        this.alertVisible = false;
        /**
         * The type of the new alias alert
         */
        this.alertType = 'is-danger';
        /**
         * The message of the new alias aliert
         */
        this.alertMessage = '';
        /**
         * A regexp validating the new alias
         */
        this.eMailRegex = /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/;
    }
    /**
     * Wether the specified alias is valid
     */
    get aliasValid() {
        return this.alias === '' || this.eMailRegex.test(this.alias);
    }
    /**
     * Add an alias
     * @param e an optional submit event
     */
    async addAlias(e) {
        if (e) {
            e.preventDefault();
        }
        try {
            this.$log.debug(`Adding alias ${this.alias}`);
            await Axios.post(`/api/account/alias`, {
                address: this.alias,
            });
            this.alias = '';
            getEmitter().emit('refresh');
            this.alertVisible = false;
        }
        catch (error) {
            this.alertType = 'is-danger';
            switch (error.response.status) {
                case 409:
                    this.$log.error(`Alias ${this.alias} already exists`);
                    this.alertMessage = this.$t('errors.409', {
                        alias: this.alias,
                    });
                    break;
                case 401:
                    this.$log.warn('Authentication failed. Showing login modal');
                    getEmitter().emit('needs-login');
                    break;
            }
            this.alertVisible = true;
        }
    }
};
NewAlias = __decorate([
    Component({
        name: 'NewAlias',
    })
], NewAlias);
export default NewAlias;
