import { __decorate } from "tslib";
import Component from 'vue-class-component';
import Vue from 'vue';
import AliasList from '@/components/AliasList.vue';
import NewAlias from '@/components/NewAlias.vue';
import Axios from 'axios';
import { getEmitter } from '@/emitter';
import AliasFilter from '@/components/AliasFilter.vue';
/**
 * The main aliasmanager component
 */
let AliasManager = class AliasManager extends Vue {
    constructor() {
        super(...arguments);
        /**
         * The login username
         */
        this.username = '';
        /**
         * The login password
         */
        this.password = '';
        /**
         * The login alert type visible
         */
        this.alertType = 'is-danger';
        /**
         * The login alert message
         */
        this.alertMessage = '';
        /**
         * Wether the login alert is visible
         */
        this.alertVisible = false;
        /**
         * Wether the login modal is visible
         */
        this.loginVisible = false;
    }
    /**
     * Register event emitter handlers
     */
    created() {
        this.$log.debug('Adding needs-login event handler');
        getEmitter().on('needs-login', () => {
            this.loginVisible = true;
        });
    }
    /**
     * Login into the system
     * @param event an optional submit event
     */
    async login(event) {
        if (event) {
            event.preventDefault();
        }
        try {
            this.$log.debug(`Logging in ${this.username}`);
            await Axios.get(`/api/auth/login`, {
                auth: {
                    username: this.username,
                    password: this.password,
                },
            });
            this.password = '';
            this.loginVisible = false;
            this.$log.debug('Login successful. Focusing new alias input');
            this.$refs.newAlias.$refs.input.focus();
            getEmitter().emit('refresh');
        }
        catch (e) {
            this.alertMessage = this.$t('errors.401');
            this.alertType = 'is-danger';
            this.alertVisible = true;
        }
    }
};
AliasManager = __decorate([
    Component({
        name: 'AliasManager',
        components: { NewAlias, AliasList, AliasFilter },
    })
], AliasManager);
export default AliasManager;
