import { __decorate } from "tslib";
import Component from 'vue-class-component';
import Vue from 'vue';
import AliasManager from '@/components/AliasManager.vue';
import Axios from 'axios';
import { getEmitter } from '@/emitter';
/**
 * The main application
 */
let App = class App extends Vue {
    /**
     * Logout of the application
     */
    async logout() {
        this.$log.debug('Logging out of the application');
        await Axios.get('/api/auth/logout');
        this.$log.debug('Refreshing the application to provoke showing the login screen');
        getEmitter().emit('refresh');
    }
};
App = __decorate([
    Component({
        name: 'App',
        components: { AliasManager },
    })
], App);
export default App;
