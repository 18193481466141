import mitt from 'mitt';
let emitter = null;
/**
 * A simple global static event emitter
 */
export function getEmitter() {
    if (!emitter) {
        emitter = mitt();
    }
    return emitter;
}
