var AliasFilter_1;
import { __decorate } from "tslib";
import Component from 'vue-class-component';
import Vue from 'vue';
import { getEmitter } from '@/emitter';
/**
 * The filter component
 */
let AliasFilter = AliasFilter_1 = class AliasFilter extends Vue {
    constructor() {
        super(...arguments);
        this.filter = '';
    }
    /**
     * Set the filter for other components
     */
    setFilter() {
        this.$log.debug(`Setting filter to ${this.filter}`);
        getEmitter().emit('set-filter', this.filter);
    }
};
AliasFilter = AliasFilter_1 = __decorate([
    Component({
        name: 'AliasFilter',
        components: { AliasFilter },
    })
], AliasFilter);
export default AliasFilter;
