import { __decorate } from "tslib";
import Component from 'vue-class-component';
import Vue from 'vue';
import Axios from 'axios';
import { getEmitter } from '@/emitter';
/**
 * The list of aliases
 */
let AliasList = class AliasList extends Vue {
    constructor() {
        super(...arguments);
        /**
         * Wether the list is loading
         */
        this.loading = false;
        /**
         * The type of the alert shown
         */
        this.alertType = 'is-danger';
        /**
         * The message of the alert
         */
        this.alertMessage = '';
        /**
         * Wether the alert is visible or not
         */
        this.alertVisible = false;
        /**
         * Wether the edit modal is visible
         */
        this.editVisible = false;
        /**
         * The old address that is edited
         */
        this.oldAddress = '';
        /**
         * The new value of the edited address
         */
        this.editAddress = '';
        /**
         * The type of the edit alert
         */
        this.editAlertType = 'is-danger';
        /**
         * The alert message in the edit modal
         */
        this.editAlertMessage = '';
        /**
         * Wether the alert message in the edit is visible
         */
        this.editAlertVisible = false;
        /**
         * The last focused edit button
         */
        this.editButtonClicked = null;
        /**
         * The list of aliases
         */
        this.aliases = [];
        /**
         * A filter for the aliases
         */
        this.filter = '';
        /**
         * A reg exp validating the alias
         */
        this.eMailRegex = /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/;
        /**
         * The paginiation page size
         */
        this.pageSize = 10;
        /**
         * The currently displayed page
         */
        this.page = 1;
        /**
         * The total number of aliases in the system matching the filter
         */
        this.total = 0;
        /**
         * Valid page sizes
         */
        this.pageSizes = [5, 10, 20, 50];
    }
    /**
     * Fill the alias table once it is mounted
     */
    mounted() {
        this.loadAliases();
    }
    /**
     * Set up event emitter handlers
     */
    created() {
        this.$log.debug('Setting up refresh event handler');
        getEmitter().on('refresh', this.loadAliases);
        this.$log.debug('Setting up set-filter event handler');
        getEmitter().on('set-filter', (filter) => {
            this.filter = filter;
            this.loadAliases();
        });
    }
    /**
     * Load the aliases from the API-server
     */
    async loadAliases() {
        try {
            this.$log.debug(`Loading aliases page ${this.page} with filter ${this.filter} with a page size of ${this.pageSize}`);
            this.loading = true;
            const response = await Axios.get(`/api/account/alias?filter=${this.filter}&page=${this.page}&pageSize=${this.pageSize}`);
            this.loading = false;
            this.aliases = response.data.results;
            this.total = response.data.total;
            this.$log.debug(`Got ${this.aliases.length} of ${this.total} aliases`);
        }
        catch (error) {
            this.loading = false;
            switch (error.response.status) {
                case 401:
                    this.$log.warn('Got unauthenticated response. Maybe you need to log in or have the wrong password?');
                    this.aliases = [];
                    getEmitter().emit('needs-login');
                    break;
            }
        }
    }
    /**
     * Called when the alias table was clicked
     * @param row row that was clicked
     * @param column column that was clicked
     * @param rowIndex index of row that was clicked
     * @param columnIndex index of column that was clicked
     */
    onTableCellClick(row, column, rowIndex, columnIndex) {
        if (columnIndex == 0) {
            this.$log.debug(`Alias ${row.address} was clicked. Opening edit modal`);
            this.editAlias(row.address);
        }
    }
    /**
     * Called when an edit button was clicked
     * @param e click event
     * @param alias selected alias
     */
    onEditButtonClicked(e, alias) {
        if (e.target) {
            this.$log.debug('Storing clicked edit button to focus later');
            this.editButtonClicked = e.target;
            this.$log.debug(`Alias ${alias} was clicked, opening edit modal`);
            this.editAlias(alias);
        }
    }
    /**
     * Opens the edit alias modal
     * @param alias the alias to be edited
     */
    editAlias(alias) {
        this.editAddress = alias;
        this.oldAddress = alias;
        this.editVisible = true;
    }
    /**
     * Saving an edit alias
     * @param e optional click event
     */
    async save(e) {
        if (e) {
            e.preventDefault();
        }
        const oldAlias = this.oldAddress;
        const newAlias = this.editAddress;
        if (oldAlias === newAlias) {
            this.$log.debug('No change was made to the alias. Simply closing the modal');
            this.editVisible = false;
            return;
        }
        try {
            this.$log.debug(`Changing alias ${oldAlias} to ${newAlias}`);
            await Axios.put(`/api/account/alias/${oldAlias}`, {
                address: newAlias,
            });
            this.editVisible = false;
            if (this.editButtonClicked) {
                this.editButtonClicked.focus();
            }
            return this.loadAliases();
        }
        catch (error) {
            this.loading = false;
            switch (error.response.status) {
                case 404:
                    this.$log.error(`Alias ${oldAlias} was not found`);
                    this.editAlertMessage = this.$t('errors.404', {
                        alias: oldAlias,
                    });
                    break;
                case 409:
                    this.$log.error(`Alias ${newAlias} already exists`);
                    this.editAlertMessage = this.$t('errors.409', {
                        alias: newAlias,
                    });
                    break;
                case 401:
                    this.$log.warn('Authentication failed. Showing login modal');
                    getEmitter().emit('needs-login');
                    break;
            }
            this.editAlertType = 'is-danger';
            this.editAlertVisible = true;
        }
    }
    /**
     * Delete an alias
     * @param address alias to delete
     */
    async deleteAlias(address) {
        try {
            this.$log.debug(`Deleting alias ${address}`);
            await Axios.delete(`/api/account/alias/${address}`, {});
            return this.loadAliases();
        }
        catch (error) {
            this.loading = false;
            switch (error.response.status) {
                case 404:
                    this.$log.error(`Alias ${address} was not found`);
                    this.alertMessage = this.$t('errors.404', {
                        alias: address,
                    });
                    break;
                case 401:
                    this.$log.warn('Authentication failed. Showing login modal');
                    getEmitter().emit('needs-login');
                    break;
            }
            this.alertType = 'is-danger';
            this.alertVisible = true;
        }
    }
    /**
     * Change the page shown
     * @param page target page
     */
    changePage(page) {
        this.page = page;
        this.loadAliases();
    }
};
AliasList = __decorate([
    Component({
        name: 'AliasList',
    })
], AliasList);
export default AliasList;
